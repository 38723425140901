<template>
  <div
    class="subheader py-2 py-lg-4 solid bg-white border-top"
    :class="{ expanded: isExpanded }"
    id="kt_subheader"
  >
    <div class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap container-fluid">
      <div class="d-flex align-items-center flex-wrap mr-1">
        <portal-target name="subheader-left"></portal-target>
      </div>
      <div class="d-flex align-items-center">
        <portal-target name="subheader-right"></portal-target>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import KTSubHeader from '@/assets/js/layout/base/subheader.js';

export default {
  name: 'KTSubheader',
  data() {
    return {
      isExpanded: false,
    };
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTSubHeader.init('kt_subheader');
  },
  computed: {
    ...mapGetters(['layoutConfig']),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig('subheader.width') === 'fluid';
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig('subheader.style');
      if (style) {
        classes.push(style);

        if (style === 'solid') {
          classes.push('bg-white');
        }

        if (this.layoutConfig('subheader.fixed')) {
          classes.push('border-top');
        }
      }
      return classes.join(' ');
    },
  },
};
</script>

<style lang="scss" scoped></style>
