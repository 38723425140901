<template>
  <b-badge class="text-light font-size-h2 py-5 px-10" :variant="variant()">{{ env }}</b-badge>
</template>
<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
    },
  },
  computed: {
    env() {
      return process.env.VUE_APP_ENV;
    },
  },
  methods: {
    variant() {
      const vueAppEnv = String(process.env.VUE_APP_ENV);
      return vueAppEnv === 'DEV'
        ? 'success'
        : vueAppEnv === 'LOCAL'
        ? 'primary'
        : vueAppEnv === 'QA'
        ? 'warning'
        : vueAppEnv === 'PROD'
        ? 'danger'
        : 'warning';
    },
  },
};
</script>
