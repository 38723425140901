<template>
  <ul class="menu-nav" v-if="!$store.getters.isUserFreelancer">
    <router-link
      custom
      to="/aufgaben/overview"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-allowedRoles="[Role.ADMIN, Role.PM, Role.YIELD, Role.AM, Role.DOM, Role.FOM, Role.CSM]"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="click"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
      >
        <a :href="href" @click="navigate" class="menu-link menu-toggle">
          <span class="menu-text">Aufgaben</span>
          <b-badge
            v-if="notificationCount > 0"
            variant="danger"
            style="
              position: absolute;
              right: -10px;
              top: -10px;
              padding: 4px;
              border-radius: 12px;
              min-width: 24px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            {{ notificationCount }}
          </b-badge>
        </a>
      </li>
    </router-link>
    <li
      :aria-haspopup="true"
      data-menu-toggle="click"
      class="menu-item menu-item-submenu menu-item-open-dropdown"
      v-bind:class="{ 'menu-item-active': hasActiveChildren('/reisen/') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text">Reisen</span>
      </a>
      <div class="menu-submenu menu-submenu-classic">
        <ul class="menu-subnav">
          <router-link custom to="/reisen/overview" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <span class="menu-text">Reiseübersicht</span>
              </a>
            </li>
          </router-link>
          <router-link
            custom
            to="/reisen/prioritaeten"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-allowedRoles="[Role.ADMIN, Role.SALES]"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <span class="menu-text">Prioritäten</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
      aria-haspopup="true"
      data-menu-toggle="click"
      class="menu-item menu-item-submenu menu-item-open-dropdown"
      v-bind:class="{ 'menu-item-active': hasActiveChildren('/reisetermine/') }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text">Reisetermine</span>
      </a>
      <div class="menu-submenu menu-submenu-classic">
        <ul class="menu-subnav">
          <router-link
            custom
            to="/reisetermine/overview"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <span class="menu-text">Reiseterminübersicht</span>
              </a>
            </li>
          </router-link>
          <li
            class="menu-item menu-item-submenu ml-0"
            data-menu-toggle="hover"
            aria-haspopup="true"
            v-allowedRoles="[Role.ADMIN, Role.YIELD, Role.PM]"
          >
            <a class="menu-link menu-toggle">
              <span class="menu-text">Planung</span>
              <i class="menu-arrow"></i>
            </a>
            <div
              class="menu-submenu menu-submenu-classic menu-submenu-right"
              data-hor-direction="menu-submenu-right"
            >
              <ul class="menu-subnav">
                <router-link
                  custom
                  to="/reisetermine/planung"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  v-allowedRoles="[Role.ADMIN, Role.PM, Role.YIELD]"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">Reiseterminplanung</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  custom
                  to="/reisetermine/flugplanung"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  v-allowedRoles="[Role.ADMIN, Role.YIELD, Role.PM]"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">Flugplanung</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  custom
                  to="/airlines"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  v-allowedRoles="[Role.ADMIN, Role.YIELD]"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="isDisabled ? '#' : href" class="menu-link" @click="navigate">
                      <span class="menu-text">Airlines</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  custom
                  to="/flugstammdaten"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  v-allowedRoles="[Role.ADMIN, Role.AM]"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="isDisabled ? '#' : href" class="menu-link" @click="navigate">
                      <span class="menu-text">Flugstammdaten</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  custom
                  to="/reisetermine/kontrollansicht-daten"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  v-allowedRoles="[Role.ADMIN, Role.PM]"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">Kontrollansicht Daten</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
          </li>

          <router-link
            custom
            to="/reisetermine/reisefinder"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <span class="menu-text">Reisefinder</span>
              </a>
            </li>
          </router-link>
          <router-link
            custom
            to="/reisetermine/releaseprozess"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <span class="menu-text">Releaseprozesse</span>
              </a>
            </li>
          </router-link>
          <router-link
            custom
            to="/reisetermine/garantiert"
            v-slot="{ href, navigate, isActive, isExactActive }"
            v-allowedRoles="[Role.ADMIN, Role.FOM]"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <span class="menu-text">Garantierte Reisetermine</span>
              </a>
            </li>
          </router-link>
          <li
            class="menu-item menu-item-submenu ml-0"
            data-menu-toggle="hover"
            aria-haspopup="true"
            v-allowedRoles="[Role.ADMIN, Role.YIELD]"
          >
            <a class="menu-link menu-toggle">
              <span class="menu-text">FVC</span>
              <i class="menu-arrow"></i>
            </a>
            <div
              class="menu-submenu menu-submenu-classic menu-submenu-right"
              data-hor-direction="menu-submenu-right"
            >
              <ul class="menu-subnav">
                <router-link
                  custom
                  to="/reisetermine/fvc/overview"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    v-allowedRoles="[Role.ADMIN, Role.YIELD]"
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">FVC-Übersicht</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  custom
                  to="/reisetermine/fvc/rm-reisebasis"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    v-allowedRoles="[Role.ADMIN, Role.YIELD]"
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">RM Reisebasis</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  custom
                  to="/reisetermine/fvc/kpi-dashboard"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    v-allowedRoles="[Role.ADMIN, Role.YIELD]"
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">KPIs</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
          </li>
          <router-link custom to="/reisetermine/zvc" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
              v-allowedRoles="[Role.ADMIN, Role.PM, Role.YIELD, Role.DOM, Role.CSM]"
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <span class="menu-text">ZVC</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
    <li
      v-allowedRoles="[Role.ADMIN, Role.OM]"
      aria-haspopup="true"
      data-menu-toggle="click"
      class="menu-item menu-item-submenu menu-item-open-dropdown"
      v-bind:class="{
        'menu-item-active':
          hasActiveChildren('/OM/') || hasActiveChildren('/kunden/') || hasActiveChildren('/agenturen/'),
      }"
    >
      <a href="#" class="menu-link menu-toggle">
        <span class="menu-text">Kunden & Agenturen</span>
      </a>
      <div class="menu-submenu menu-submenu-classic">
        <ul class="menu-subnav">
          <li class="menu-item menu-item-submenu" data-menu-toggle="hover" aria-haspopup="true">
            <a class="menu-link menu-toggle">
              <span class="svg-icon menu-icon"></span>
              <span class="menu-text">Kunden</span>
              <i class="menu-arrow"></i>
            </a>
            <div
              class="menu-submenu menu-submenu-classic menu-submenu-right"
              data-hor-direction="menu-submenu-right"
            >
              <ul class="menu-subnav">
                <router-link
                  custom
                  to="/kunden/overview"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">{{ $t('COMMON.CARDS.OVERVIEW') }}</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  custom
                  to="/kunden/cleaning"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">{{ $t('DUBLETTEN.BEREINIGUNG') }}</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  custom
                  to="/kunden/confirmation"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">{{ $t('KUNDEN.DUBLETTEN.PRUEFUNG') }}</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
          </li>
          <li class="menu-item menu-item-submenu" data-menu-toggle="hover" aria-haspopup="true">
            <a class="menu-link menu-toggle">
              <span class="svg-icon menu-icon"></span>
              <span class="menu-text">Agenturen</span>
              <i class="menu-arrow"></i>
            </a>
            <div
              class="menu-submenu menu-submenu-classic menu-submenu-right"
              data-hor-direction="menu-submenu-right"
            >
              <ul class="menu-subnav">
                <router-link
                  custom
                  to="/agenturen/overview"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">{{ $t('COMMON.CARDS.OVERVIEW') }}</span>
                    </a>
                  </li>
                </router-link>
                <router-link
                  custom
                  to="/agenturen/confirmation"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item"
                    :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
                  >
                    <a :href="href" class="menu-link" @click="navigate">
                      <span class="menu-text">{{ $t('KUNDEN.DUBLETTEN.PRUEFUNG') }}</span>
                    </a>
                  </li>
                </router-link>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </li>
    <router-link
      custom
      to="/umfragen/"
      v-slot="{ href, navigate, isActive, isExactActive }"
      v-allowedRoles="[Role.ADMIN]"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="click"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
        class="menu-item menu-item-submenu menu-item-open-dropdown"
      >
        <a :href="href" @click="navigate" class="menu-link menu-toggle">
          <span class="menu-text">Umfragen</span>
        </a>
      </li>
    </router-link>
  </ul>
  <ul class="menu-nav" v-else>
    <router-link custom to="/reisetermine/overview" v-slot="{ href, navigate, isActive, isExactActive }">
      <a
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">{{ $t('COMMON.REISETERMINUEBERSICHT') }}</span>
        </a>
      </a>
    </router-link>
    <router-link custom to="/reisetermine/reisefinder" v-slot="{ href, navigate, isActive, isExactActive }">
      <a
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
      >
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text">{{ $t('COMMON.REISEFINDER') }}</span>
        </a>
      </a>
    </router-link>
  </ul>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'KTMenu',
  methods: {
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1;
    },
  },
  computed: {
    ...mapState({
      notificationCount: state => state.aufgaben.notificationCount,
    }),
    isDisabled() {
      return true;
    },
  },
};
</script>
