<template>
  <ul class="menu-nav" v-if="!$store.getters.isUserFreelancer">
    <li
      @click.prevent="toggleAside"
      aria-haspopup="true"
      data-menu-toggle="hover"
      class="menu-item menu-item-submenu"
      v-bind:class="{ 'menu-item-open': hasActiveChildren('/error/error') }"
      v-allowedRoles="[Role.ADMIN]"
    >
      <a href="#" class="menu-link menu-toggle">
        <i class="menu-icon flaticon2-list-2"></i>
        <span class="menu-text">{{ $t('REISEN.COMMON.SETTINGS') }}</span>
        <i class="menu-arrow"></i>
      </a>
      <div class="menu-submenu">
        <span class="menu-arrow"></span>
        <ul class="menu-subnav">
          <router-link
            custom
            to="/einstellungen/testdata"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t('COMMON.TESTDATEN') }}</span>
              </a>
            </li>
          </router-link>
          <router-link
            custom
            to="/einstellungen/simulate"
            v-slot="{ href, navigate, isActive, isExactActive }"
          >
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t('COMMON.SIMULATION') }}</span>
              </a>
            </li>
          </router-link>
          <router-link custom to="/einstellungen/user" v-slot="{ href, navigate, isActive, isExactActive }">
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item"
              :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']"
            >
              <a :href="href" class="menu-link" @click="navigate">
                <i class="menu-bullet menu-bullet-dot">
                  <span></span>
                </i>
                <span class="menu-text">{{ $t('COMMON.NUTZEREINSTELLUNGEN') }}</span>
              </a>
            </li>
          </router-link>
        </ul>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'KTMenu',
  methods: {
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1;
    },
    toggleAside($event) {
      const isSideMenuToggled = window.KTLayoutAsideToggle.getToggle().getState();
      // Why is this not a boolean?????
      if (isSideMenuToggled === 'on') {
        window.KTLayoutAsideToggle.getToggle().toggle($event);
      }
    },
  },
};
</script>
