<template>
  <div class="d-flex flex-column flex-root">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled"></KTAside>
      <!-- end:: Aside Left -->

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div id="kt_content" class="content d-flex flex-column flex-column-fluid pt-4">
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader v-if="subheaderDisplay" />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid content-body">
            <div
              class="px-5"
              :class="{
                'container-fluid': contentFluid,
                container: !contentFluid,
              }"
            >
              <transition v-if="isAnimationEnabled" :name="isAnimationEnabled ? 'fade-in-up' : null">
                <router-view />
              </transition>
              <router-view v-else />
              <UmfragenWidget />
            </div>
          </div>
        </div>
      </div>
    </div>
    <KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>
    <KTScrollTop></KTScrollTop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapState } from 'vuex';
import KTAside from '@/view/layout/aside/Aside.vue';
import KTHeader from '@/view/layout/header/Header.vue';
import KTHeaderMobile from '@/view/layout/header/HeaderMobile.vue';
import KTFooter from '@/view/layout/footer/Footer.vue';
import KTContent from '@/assets/js/layout/base/content.js';
import UmfragenWidget from '@/components/umfragen/umfragen-widget.vue';

import HtmlClass from '@/core/common/services/htmlclass.service';
import KTSubheader from '@/view/layout/subheader/Subheader.vue';
import KTStickyToolbar from '@/view/layout/extras/StickyToolbar.vue';
import KTScrollTop from '@/view/layout/extras/ScrollTop';
import Loader from '@/view/content/Loader.vue';
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from '@/core/common/stores/htmlclass.module.js';

export default {
  name: 'Layout',
  components: {
    KTAside,
    KTHeader,
    KTHeaderMobile,
    KTFooter,
    KTSubheader,
    KTStickyToolbar,
    KTScrollTop,
    Loader,
    UmfragenWidget,
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');
    }, 2000);
    // Init Content
    KTContent.init('kt_content');
  },
  methods: {},
  computed: {
    ...mapGetters(['layoutConfig']),
    ...mapState({
      isAnimationEnabled: state => state.userSettingsLocal.isAnimationEnabled,
    }),
    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig('content.width') === 'fluid';
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return !!this.layoutConfig('aside.self.display');
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    },

    /**
     * Set the subheader display
     * @returns {boolean}
     */
    subheaderDisplay() {
      return !!this.layoutConfig('subheader.display');
    },
  },
};
</script>
