<template>
  <div v-if="isActiveUmfrageForCurrentUserDepartment && !alreadyAnsweredActiveUmfrage">
    <div v-if="!showModal" class="umfragen-widget bg-warning" @click="toggleShow">
      <h2 class="mb-0">?</h2>
    </div>
    <b-modal id="umfragen-widget-modal" v-model="showModal" title="Bitte beantworte die Umfrage">
      <b-overlay :show="isLoading">
        <div style="display: flex; gap: 24px; flex-direction: column">
          <b-form-group v-for="frage in fragen" :key="'frage-' + frage.frage">
            <template #label>
              <span style="font-size: medium">{{ frage.frage }}</span>
            </template>
            <StarRatingInput v-model="frage.antwort" />
          </b-form-group>
          <b-form-group>
            <template #label>
              <span style="font-size: medium">Kommentar</span>
            </template>
            <b-form-input size="lg" v-model="kommentar" placeholder="Schreibe einen Kommentar..." />
          </b-form-group>
        </div>
      </b-overlay>
      <template #modal-footer="{ hide }" class="pl-1">
        <b-button
          class="mr-4"
          :disabled="disableSichernButton"
          :variant="disableSichernButton ? 'secondary' : 'success'"
          @click="saveAntwort(hide)"
        >
          Speichern
        </b-button>
        <b-button @click="hide">Abbrechen</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import StarRatingInput from '@/components/common/star-rating-input.vue';
import { SAVE_ANTWORT } from '@/core/umfragen/umfragen.module';

export default {
  components: {
    StarRatingInput,
  },
  data() {
    return {
      isLoading: false,
      showModal: true,
      fragen: [
        { frage: 'Wie zufrieden bist du mit den neuen Features?', antwort: 0 },
        { frage: 'Wie nutzerfreundlich findest du die neuen Features?', antwort: 0 },
        { frage: 'Wie gefällt dir die Benutzeroberfläche?', antwort: 0 },
      ],
      kommentar: '',
      ratingFrage1: 0,
    };
  },
  computed: {
    ...mapGetters(['currentUserId', 'getActiveUmfrageAntwortenUserIds']),
    ...mapState({
      users: state => state.users.users,
      activeUmfrage: state => state.umfragen.activeUmfrage,
    }),
    getUserDepartment() {
      if (this.users?.length === 0) return null;
      if (!this.currentUserId) return null;
      return this.users.find(user => user.id === this.currentUserId)?.department;
    },
    isActiveUmfrageForCurrentUserDepartment() {
      if (!this.activeUmfrage) return false;
      if (this.getUserDepartment === null) return false;
      return this.activeUmfrage.teams.includes(this.getUserDepartment);
    },
    disableSichernButton() {
      return this.fragen.some(frage => frage.antwort === 0);
    },
    alreadyAnsweredActiveUmfrage() {
      if (!this.currentUserId) return false;
      return this.getActiveUmfrageAntwortenUserIds.includes(this.currentUserId);
    },
  },
  methods: {
    toggleShow() {
      this.showModal = !this.showModal;
    },
    saveAntwort(hide) {
      this.isLoading = true;
      this.$store
        .dispatch(SAVE_ANTWORT, {
          antwort1: this.fragen[0].antwort,
          antwort2: this.fragen[1].antwort,
          antwort3: this.fragen[2].antwort,
          kommentar: this.kommentar,
          umfrageId: this.activeUmfrage.id,
        })
        .then(() => {
          this.isLoading = false;
          this.toast('Umfrage erfolgreich beantwortet!');
          hide();
        })
        .catch(() => (this.isLoading = false));
    },
  },
};
</script>
<style scoped>
.umfragen-widget {
  cursor: pointer;
  position: absolute;
  height: 50px;
  width: 50px;
  right: 25px;
  bottom: 25px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  z-index: 99;
}
:deep(.modal-footer) {
  padding: 0.8rem;
}
:deep(.modal-header) {
  padding: 1rem;
}
</style>
