import { render, staticRenderFns } from "./umfragen-widget.vue?vue&type=template&id=50bcc38f&scoped=true"
import script from "./umfragen-widget.vue?vue&type=script&lang=js"
export * from "./umfragen-widget.vue?vue&type=script&lang=js"
import style0 from "./umfragen-widget.vue?vue&type=style&index=0&id=50bcc38f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50bcc38f",
  null
  
)

export default component.exports