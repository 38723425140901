<template>
  <!-- begin:: Footer -->
  <div ref="kt_footer" class="footer bg-white py-1 d-flex flex-lg-column" id="kt_footer">
    <div
      class="d-flex align-items-center justify-content-between"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="text-dark order-2 order-md-1 nav-link">
        <span class="text-muted font-weight-bold mr-2">© {{ new Date().getFullYear() }}</span>
        <a href="http://skr.de" target="_blank" class="text-dark-75 text-hover-primary"> SKR Reisen </a>
      </div>
      <div class="nav nav-dark"></div>
    </div>
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from 'vuex';
import KTFooter from '@/assets/js/layout/base/footer.js';

export default {
  name: 'KTFooter',
  mounted() {
    KTFooter.init('kt_footer');
  },
  computed: {
    ...mapGetters(['layoutConfig']),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig('footer.width') === 'fluid';
    },
  },
  methods: {
    greet: function (event) {
      event.preventDefault();
      alert(
        'Unter diesem Link wird euch das Tech-Team bald mit Informationen über den aktuellen Stand der Systeme, Releaseses, Downtimes, usw. informieren.'
      );
    },
  },
};
</script>
