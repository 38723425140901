<template>
  <div class="d-flex" style="gap: 8px">
    <div
      v-for="(fillPercent, i) in getPerStarRating"
      :key="'star' + i"
      @click="onClickStar(i)"
      class="cursor"
    >
      <Star :fillPercent="fillPercent" />
    </div>
  </div>
</template>
<script>
import Star from '@/components/common/star';
export default {
  components: {
    Star,
  },
  props: { value: { type: Number } },
  data() {
    return {};
  },
  computed: {
    getPerStarRating() {
      let localRating = this.value;
      const stars = Array(5).fill(0);
      for (const i in stars) {
        stars[i] = localRating >= 1 ? 1 : Math.round(localRating * 100) / 100;
        localRating = Math.max(localRating - 1, 0);
      }
      return stars;
    },
  },
  methods: {
    onClickStar(i) {
      this.$emit('input', i + 1);
    },
  },
};
</script>
<style scoped>
.cursor {
  cursor: pointer;
}
</style>
